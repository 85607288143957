import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams  } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EndpointFactory } from './endpoint-factory.service';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class CertifiedExaminerSelectionApplicationEndpoint extends EndpointFactory {
  private readonly getCountryVldEndpointPath: string = "/api/ref/getCountries";
  private readonly getProvinceStatesEndpointPath: string = "/api/ref/getProvinceStates";
  private readonly getCitiesEndpointPath: string = "/api/ref/getCities";
  private readonly getCertifiedExaminerApplicationTypesEndpointPath: string = "/api/certifiedExaminerSelectionApplication/getCertifiedExaminerApplicationTypes";
  private readonly getCertifiedExaminersEndpointPath: string = "/api/certifiedExaminerSelectionApplication/getCertifiedExaminers";
  private readonly getInsuranceCompanyEndpointPath: string = "/api/certifiedExaminerSelectionApplication/getInsuranceCompanies";
  private readonly getCertifiedExaminerClaimantGenderEndpointPath: string = "/api/certifiedExaminerSelectionApplication/getCertifiedExaminerClaimantGenders";
  private readonly submitCEApplicationEndpointPath: string = "/api/certifiedExaminerSelectionApplication/submitCEApplication";
  private readonly getRandomSelectionForEligibilityPath: string = "api/certifiedExaminerSelectionApplication/getRandomSelectionForEligibility";
  private readonly acceptSelectionEndpointUrlPath: string = "api/certifiedExaminerSelectionApplication/acceptSelection";
  private readonly declineSelectionEndpointUrlPath: string = "api/certifiedExaminerSelectionApplication/declineSelection";
  private readonly getPdfCertifiedExaminerApplicationEndpointPath: string = "/api/certifiedExaminerSelectionApplication/getPdfCertifiedExaminerApplication";
  private readonly getPdfCertifiedExaminerSelectionEndpointPath: string = "/api/certifiedExaminerSelectionApplication/getPdfCertifiedExaminerSelection";
  private readonly getPdfCertifiedExaminerConfirmationLetterEndPointPath: string = "/api/certifiedExaminerSelectionApplication/getPdfCertifiedExaminerConfirmationLetter";
  private readonly getPdfClaimantConfirmationLetterEndPointPath: string = "/api/certifiedExaminerSelectionApplication/getPdfClaimantConfirmationLetter";
  private readonly getRandomSelectionForConfirmationEndpointPath: string = "/api/certifiedExaminerSelectionApplication/getRandomSelectionForConfirmation";
  
  get getCountryVldEndpointUrl() { return this.configurations.baseUrl + this.getCountryVldEndpointPath; }
  get getProvinceStatesEndpointUrl() { return this.configurations.baseUrl + this.getProvinceStatesEndpointPath; }
  get getCitiesEndpointUrl() { return this.configurations.baseUrl + this.getCitiesEndpointPath; }
  get getCertifiedExaminerApplicationTypesEndpointUrl() { return this.configurations.baseUrl + this.getCertifiedExaminerApplicationTypesEndpointPath; }
  get getCertifiedExaminersEndpointUrl () { return this.configurations.baseUrl + this.getCertifiedExaminersEndpointPath; }
  get getInsuranceCompanyEndpointUrl() { return this.configurations.baseUrl + this.getInsuranceCompanyEndpointPath; }
  get getCertifiedExaminerClaimantGenderEndpointUrl() { return this.configurations.baseUrl + this.getCertifiedExaminerClaimantGenderEndpointPath; }
  get getPdfCertifiedExaminerApplicationEndpointUrl() { return this.configurations.baseUrl + this.getPdfCertifiedExaminerApplicationEndpointPath; }
  get submitCEApplicationEndpointUrl() { return this.configurations.baseUrl + this.submitCEApplicationEndpointPath; }
  get getRandomSelectionForEligibilityEndpointUrl() { return this.configurations.baseUrl + this.getRandomSelectionForEligibilityPath; }
  get acceptSelectionEndpointUrl() { return this.configurations.baseUrl + this.acceptSelectionEndpointUrlPath; }
  get declineSelectionEndpointUrl() { return this.configurations.baseUrl + this.declineSelectionEndpointUrlPath; }
  get getPdfCertifiedExaminerSelectionEndpointUrl() { return this.configurations.baseUrl + this.getPdfCertifiedExaminerSelectionEndpointPath; }
  get getPdfCertifiedExaminerConfirmationLetterEndpointUrl() { return this.configurations.baseUrl + this.getPdfCertifiedExaminerConfirmationLetterEndPointPath; }
  get getPdfClaimantConfirmationLetterEndpointUrl() { return this.configurations.baseUrl + this.getPdfClaimantConfirmationLetterEndPointPath; }
  get getRandomSelectionForConfirmationEndpointUrl() { return this.configurations.baseUrl + this.getRandomSelectionForConfirmationEndpointPath; }
  

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {
    super(http, configurations, injector);

  }

  getGenders<T>(): Observable<T> {
    let endpointUrl = `${this.getCertifiedExaminerClaimantGenderEndpointUrl}/`;
    return this.http.get<T>(endpointUrl);
  }

  getCountryVldEndpoint<T>(): Observable<T> {

    let endpointUrl = `${this.getCountryVldEndpointUrl}/`;
    return this.http.get<T>(endpointUrl);
  }

  getProvinceStatesEndpoint<T>(): Observable<T> {

    let endpointUrl = `${this.getProvinceStatesEndpointUrl}/`;
    return this.http.get<T>(endpointUrl);
  }

  getCitiesEndpoint<T>(): Observable<T> {

    let endpointUrl = `${this.getCitiesEndpointUrl}/`;
    return this.http.get<T>(endpointUrl);
  }

  getCertifiedExaminerApplicationTypesEndpoint<T>(): Observable<T> {

    let endpointUrl = `${this.getCertifiedExaminerApplicationTypesEndpointUrl}/`;
    return this.http.get<T>(endpointUrl);
  }

  getCertifiedExaminerClaimantGendersEndpoint<T>(): Observable<T> {

    let endpointUrl = `${this.getCertifiedExaminerClaimantGenderEndpointUrl}/`;
  return this.http.get<T>(endpointUrl);
  }

  getCertifiedExaminersEndpoint<T>(): Observable<T> {

    let endpointUrl = `${this.getCertifiedExaminersEndpointUrl}/`;
    return this.http.get<T>(endpointUrl);
  }

  getInsuranceCompaniesEndpoint<T>(): Observable<T> {

    let endpointUrl = `${this.getInsuranceCompanyEndpointUrl}/`;
    return this.http.get<T>(endpointUrl);
  }
  
  submitCEApplicationEndpoint<T>(userObject: any): Observable<T> {
    let endpointUrl = `${this.submitCEApplicationEndpointUrl}/`;
    //console.log(this.http.post<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders()));
    return this.http.post<T>(endpointUrl, JSON.stringify(userObject), this.getRequestHeaders());
  }

 getPdfCertifiedExaminerApplicationEndpoint<T>(certifiedExaminerSelectionApplicationId?: string): Observable<Blob> {
   let endpointUrl = `${this.getPdfCertifiedExaminerApplicationEndpointUrl}/${certifiedExaminerSelectionApplicationId}/`;
  return this.http.get(endpointUrl, { responseType: "blob" });
 }
  
  getPdfCertifiedExaminerApplicationReportURLEndpoint<T>(certifiedExaminerSelectionApplicationId?: string): string {
    let endpointUrl = `${this.getPdfCertifiedExaminerApplicationEndpointUrl}/${certifiedExaminerSelectionApplicationId}/`;
    return endpointUrl;
  }

  getPdfCertifiedExaminerConfirmationLetterEndpoint<T>(certifiedExaminerRandomSelectionId?: string): Observable<Blob> {
    let endpointUrl = `${this.getPdfCertifiedExaminerConfirmationLetterEndpointUrl}/${certifiedExaminerRandomSelectionId}/`;
    return this.http.get(endpointUrl, { responseType: "blob" });
  }

  getPdfCertifiedExaminerConfirmationLetterReportURLEndpoint<T>(certifiedExaminerRandomSelectionId?: string): string {
    let endpointUrl = `${this.getPdfCertifiedExaminerConfirmationLetterEndpointUrl}/${certifiedExaminerRandomSelectionId}/`;
    return endpointUrl;
  }

  getPdfClaimantConfirmationLetterEndpoint<T>(certifiedExaminerRandomSelectionId?: string): Observable<Blob> {
    let endpointUrl = `${this.getPdfClaimantConfirmationLetterEndpointUrl}/${certifiedExaminerRandomSelectionId}/`;
    return this.http.get(endpointUrl, { responseType: "blob" });
  }

  getPdfClaimantConfirmationLetterReportURLEndpoint<T>(certifiedExaminerRandomSelectionId?: string): string {
    let endpointUrl = `${this.getPdfClaimantConfirmationLetterEndpointUrl}/${certifiedExaminerRandomSelectionId}/`;
    return endpointUrl;
  }

  getPdfCertifiedExaminerSelectionEndpoint<T>(certifiedExaminerRandomSelectionId?: string): Observable<Blob> {
    let endpointUrl = `${this.getPdfCertifiedExaminerSelectionEndpointUrl}/${certifiedExaminerRandomSelectionId}/`;
    return this.http.get(endpointUrl, { responseType: "blob" });
  }
  
  getPdfCertifiedExaminerSelectionReportURLEndpoint<T>(certifiedExaminerSelectionId?: string): string {
    let endpointUrl = `${this.getPdfCertifiedExaminerSelectionEndpointUrl}/${certifiedExaminerSelectionId}/`;
    return endpointUrl;
  }

  getRandomSelectionForConfirmationEndpoint<T>(urlCode: string): Observable<T> {
    let endpointUrl = `${this.getRandomSelectionForConfirmationEndpointUrl}/${urlCode}`;
    return this.http.get<T>(endpointUrl);
  }
  
  getRandomSelectionForEligibilityEndpoint<T>(urlCode: string): Observable<T> {
    let endpointUrl = `${this.getRandomSelectionForEligibilityEndpointUrl}/${urlCode}`;
    return this.http.get<T>(endpointUrl);
  }

  acceptSelectionEndpoint<T>(urlCode?: string): Observable<T> {
    let endpointUrl = `${this.acceptSelectionEndpointUrl}/`;
    //let endpointUrl = `${this.acceptSelectionEndpointUrl}/${urlCode}`;
    const body = {
      urlcode: urlCode
    };

    return this.http.post<T>(endpointUrl, JSON.stringify(body), this.getRequestHeaders());
   //return this.http.get<T>(endpointUrl);
  }

  declineSelectionEndpoint<T>(urlCode: string): Observable<T> {
    let endpointUrl = `${this.declineSelectionEndpointUrl}/`;
    //let endpointUrl = `${this.acceptSelectionEndpointUrl}/${urlCode}`;
    const body = {
      urlcode: urlCode
    };

    return this.http.post<T>(endpointUrl, JSON.stringify(body), this.getRequestHeaders());
    //return this.http.get<T>(endpointUrl);
  }

}
