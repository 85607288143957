import { CertifiedExaminerSelectionApplicationDto } from '../../core/models/CertifiedExaminerSelectionApplication.model';

export class CertifiedExaminerRandomSelectionDto {
  public certifiedExaminerRandomSelectionID: string;
  public certifiedExaminerRandomSelectionNumber: number;
  public randomSelectionDate: Date;
  public responseDueDate: Date;
  public certifiedExaminerContactID: string;
  public certifiedExaminerName: string;
  public certifiedExaminerRandomSelectionStatusVldID: number;
  public certifiedExaminerResponseDate: Date;
  public certifiedExaminerSelectionApplication: CertifiedExaminerSelectionApplicationDto;
}
