import { Component, OnInit, NgModule, Pipe, PipeTransform, enableProdMode, Input, ViewChild } from '@angular/core';
import { BrowserModule, DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { RouterModule, Router, ActivatedRoute, Params } from '@angular/router';
import { confirm } from 'devextreme/ui/dialog';
import {DxFormComponent} from 'devextreme-angular';
import { CertifiedExaminerSelectionApplicationService } from '../../core/services/CertifiedExaminerSelectionApplication.service';
import { AlertService, MessageSeverity } from '../../core/services/alert.service';
import notify from 'devextreme/ui/notify';
import { CertifiedExaminerRandomSelectionDto } from '../../core/models/CertifiedExaminerRandomSelectionDto.model';
import { CertifiedExaminerSelectionApplicationDto } from '../../core/models/CertifiedExaminerSelectionApplication.model';
import { Utilities } from '../../shared/utilities/utilities';
import { BusinessRuleMessage } from '../../core/models/BusinessRuleMessage.model';
import { CertifiedExaminerPasscodeViewComponent } from '../CertifiedExaminerPasscodeView/CertifiedExaminerPasscodeView.component';

@Component({
  selector: 'CertifiedExaminerEligibilityView',
  templateUrl: './CertifiedExaminerEligibilityView.component.html'
})

export class CertifiedExaminerEligibilityViewComponent { //implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;
  certifiedExaminerRandomSelection: CertifiedExaminerRandomSelectionDto;
  certifiedExaminerSelectionApplication: CertifiedExaminerSelectionApplicationDto;
  viewName: string = "CertifiedExaminerEligibilityView";
  urlCode: string;
  isSubmitted: boolean = false;
  ////private isSaving = false;
  ////private showValidationErrors = false;
  //public innerHtml: SafeHtml;
  isPopupVisible = false;
  isLoadPanelVisible = false;
  claimantDateofBirth: string;

  //passcodeValue: string;
  //showPasscode: boolean;
  //showMainContent: boolean;
  //accept: string;
  //decline: boolean;

  //businessRuleMessages: BusinessRuleMessage[];
  
  //collapsed = false;
  pdfSrc: SafeResourceUrl;
  
  radioGroupItems = [
    { text: "YES - I am eligible to assess this client as a Certified Examiner", value: 2},
    { text: "NO - I am not eligible to assess this client as a Certified Examiner", value: 3}
  ];

  constructor(private alertService: AlertService, private certifiedExaminerSelectionApplicationService: CertifiedExaminerSelectionApplicationService, private router: Router, private sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute) {
    //this.showPasscode = true;
    //this.showMainContent = false;
    //this.accept = "yes";
    //this.decline = false;
    this.save = this.save.bind(this);
    this.getCERandomSelectionChange = this.getCERandomSelectionChange.bind(this);
    this.getCEApplicationChange = this.getCEApplicationChange.bind(this);
    this.fieldDataChanged = this.fieldDataChanged.bind(this);
    this.selectionStatusRequired = this.selectionStatusRequired.bind(this);
  }

  fieldDataChanged(e: any) {
    this.form.instance.validate();
  }

  getCERandomSelectionChange($event) {
     this.certifiedExaminerRandomSelection = $event;
  }

  getCEApplicationChange($event) {
    this.certifiedExaminerSelectionApplication = $event;
    this.claimantDateofBirth = Utilities.printDateOnly(this.certifiedExaminerSelectionApplication.claimantDateofBirth);
  }

  submitButtonOptions: any = {
    text: "Submit Response",
    type: "success",
    useSubmitBehavior: true,
    width: 150
  }

  declineButtonOptions: any = {
    text: "NO - I am not eligible to assess this client as a Certified Examiner",
    type: "success",
    width: 500
  }

  ////passcodeButtonOptions: any = {
  ////  text: "submit",
  ////  type: "success",
  ////  mode: "contained",
  ////  elementAttr: { "id": "certdownload" },
  ////  onClick: 'passcodeButtonClick',
  ////  width: 500
  ////}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.urlCode = params['id'];
    });
  }

  // Form Validation: Yes or No must be selected
  selectionStatusRequired(e) {
  if (this.certifiedExaminerRandomSelection.certifiedExaminerRandomSelectionStatusVldID == 1) {
      return false;
    }
    return true;
  }

  // call Save When Submit Response is clicked
  save(e) {
    //console.log(this.certifiedExaminerRandomSelection.certifiedExaminerRandomSelectionStatusVldID);
    //console.log(this.urlCode);

    let responseText = this.radioGroupItems.filter(x => x.value ==
      this.certifiedExaminerRandomSelection.certifiedExaminerRandomSelectionStatusVldID)[0].text;
    
    var result = confirm("Are you sure you want to submit your response? ", "Confirm Response");
    result.then((dialogResult: any) => {
      if (dialogResult) {
        this.isLoadPanelVisible = true;
        if (this.certifiedExaminerRandomSelection.certifiedExaminerRandomSelectionStatusVldID == 2) {
           //accept
          this.certifiedExaminerSelectionApplicationService.acceptSelection(this.urlCode).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
        }
        else if (this.certifiedExaminerRandomSelection.certifiedExaminerRandomSelectionStatusVldID == 3) {
           //decline
          this.certifiedExaminerSelectionApplicationService.declineSelection(this.urlCode).subscribe(response => this.saveSuccessHelper(), error => this.saveFailedHelper(error));
        }
      }
    });

    this.isLoadPanelVisible = false;
    e.preventDefault();
  }

  private saveSuccessHelper() {
    // set isSubmitted = true will open the submission view page to show the confirmation message and allow to print report
    this.isSubmitted = true;
    this.isLoadPanelVisible = false;
  }

  private saveFailedHelper(error: any) {
    this.alertService.stopLoadingMessage();
    notify("Errors occured while submitting your response. Please try again.", "error", 6000);
    this.isLoadPanelVisible = false;
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }
}
