import { Component, OnInit, NgModule, Pipe, PipeTransform, enableProdMode, Input, ViewChild } from '@angular/core';
import { BrowserModule, DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { RouterModule, Router, ActivatedRoute, Params } from '@angular/router';
import { confirm } from 'devextreme/ui/dialog';

import { DxFormComponent } from 'devextreme-angular';
import { CertifiedExaminerSelectionApplicationService } from '../../core/services/CertifiedExaminerSelectionApplication.service';
import { AlertService, MessageSeverity } from '../../core/services/alert.service';
import notify from 'devextreme/ui/notify';
import { CertifiedExaminerRandomSelectionDto } from '../../core/models/CertifiedExaminerRandomSelectionDto.model';
import { CertifiedExaminerSelectionApplicationDto } from '../../core/models/CertifiedExaminerSelectionApplication.model';
import { Utilities } from '../../shared/utilities/utilities';
import { BusinessRuleMessage } from '../../core/models/BusinessRuleMessage.model';


@Component({
  selector: 'CE-ConfirmationDetailView',
  templateUrl: './CertifiedExaminerConfirmationDetailView.component.html'
})

export class CertifiedExaminerConfirmationDetailViewComponent { //implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;
  certifiedExaminerRandomSelection: CertifiedExaminerRandomSelectionDto;
  certifiedExaminerSelectionApplication: CertifiedExaminerSelectionApplicationDto;
  viewName: string = "CertifiedExaminerConfirmationDetailView";
  urlCode: string;
  //public innerHtml: SafeHtml;
  isCEPopupVisible = false;
  isClaimantPopupVisible = false;
  isLoadPanelVisible = false;

  //passcodeValue: string;
  //showPasscode: boolean;
  //showMainContent: boolean;
  
  //collapsed = false;
  pdfSrc: SafeResourceUrl;
  expiredDate: string;
  claimantDateofBirth: string;
 
  constructor(private alertService: AlertService, private certifiedExaminerSelectionApplicationService: CertifiedExaminerSelectionApplicationService, private router: Router, private sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute) {
    //this.showPasscode = true;
    //this.showMainContent = false;
    this.previewCELetter = this.previewCELetter.bind(this);
    this.previewClaimantLetter = this.previewClaimantLetter.bind(this);
    this.printCELetter = this.printCELetter.bind(this);
    this.printClaimantLetter = this.printClaimantLetter.bind(this);
    this.downloadCELetter = this.downloadCELetter.bind(this);
    this.downloadClaimantLetter = this.downloadClaimantLetter.bind(this);
    this.getCERandomSlectionChange = this.getCERandomSlectionChange.bind(this);
    this.getCEApplicationChange = this.getCEApplicationChange.bind(this);   
  }

  previewCertifiedExaminerLetterButtonOption: any = {
    text: "Preview Certified Examiner Confirmation Letter",
    type: "default",
    onClick: 'previewCELetter',
    width: 400
  }

  previewClaimantLetterButtonOption: any = {
    text: "Preview Claimant Confirmation Letter",
    type: "default",
    onClick: 'previewClaimantLetter',
    width: 400
  }

  ////passcodeButtonOptions: any = {
  ////  text: "submit",
  ////  type: "success",
  ////  mode: "contained",
  ////  elementAttr: { "id": "certdownload" },
  ////  onClick: 'passcodeButtonClick',
  ////  width: 500
  ////}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.urlCode = params['id'];
    });
  }

  getCERandomSlectionChange($event) {
    this.certifiedExaminerRandomSelection = $event;
  }

  getCEApplicationChange($event) {
    this.certifiedExaminerSelectionApplication = $event;
    this.claimantDateofBirth = Utilities.printDateOnly(this.certifiedExaminerSelectionApplication.claimantDateofBirth);
    this.expiredDate = Utilities.printDateOnly(this.certifiedExaminerSelectionApplication.expiredDate);
  }

  previewClaimantLetter(e) {
    
    this.isLoadPanelVisible = true;
    this.certifiedExaminerSelectionApplicationService.getPdfClaimantConfirmationLetter(this.urlCode).subscribe(response => {
      this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.certifiedExaminerSelectionApplicationService.getPdfClaimantConfirmationLetterReportURL(this.urlCode));
      console.log(this.pdfSrc);
      this.isClaimantPopupVisible = true;
      this.isLoadPanelVisible = false;
    });

    //e.preventDefault();
  }

  previewCELetter(e) {
     this.isLoadPanelVisible = true;
     this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerConfirmationLetter(this.urlCode).subscribe(response => {
       this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerConfirmationLetterReportURL(this.urlCode));
      this.isCEPopupVisible = true;
      this.isLoadPanelVisible = false;
    });

    //e.preventDefault();
  }


  printCELetter() {
    this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerConfirmationLetter(this.urlCode).subscribe(response => {
      const objectUrl: string = URL.createObjectURL(response);
      window.open(objectUrl).print();
    });
  }

  printClaimantLetter() {
    this.certifiedExaminerSelectionApplicationService.getPdfClaimantConfirmationLetter(this.urlCode).subscribe(response => {
      const objectUrl: string = URL.createObjectURL(response);
      window.open(objectUrl).print();
    });
  }

  downloadCELetter() {
    ////console.log(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID);
    this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerConfirmationLetter(this.urlCode).subscribe(response => {
      var fileName = "CertifiedExaminerConfirmationLetter" + ".pdf";
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } else {
        const objectUrl: string = URL.createObjectURL(response);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
      }
    });
  }

  downloadClaimantLetter() {
    ////console.log(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID);
    this.certifiedExaminerSelectionApplicationService.getPdfClaimantConfirmationLetter(this.urlCode).subscribe(response => {
      var fileName = "ClaimantConfirmationLetter" + ".pdf";
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } else {
        const objectUrl: string = URL.createObjectURL(response);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
      }
    });
  }
}
