import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ConfigurationService } from './configuration.service';


@Injectable()
export class EndpointFactory {

  private readonly _loginUrl: string = "/api/insurance/getuser";

  get loginUrl() { return this.configurations.baseUrl + this._loginUrl; }

  private taskPauser: Subject<any>;

  constructor(protected http: HttpClient, protected configurations: ConfigurationService, private injector: Injector) {

  }

  protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
    });

    return { headers: headers };
  }
  
  protected handleError(error) {
    
    return Observable.throw(error);
  }
 
  getUserEndpoint<T>(email?: string): Observable<T> {
    let endpointUrl = `${this.loginUrl}/${email}/`;
    return this.http.get<T>(endpointUrl, this.getRequestHeaders());
  }

 
  private pauseTask(continuation: () => Observable<any>) {
    if (!this.taskPauser)
      this.taskPauser = new Subject();

    ////return this.taskPauser.pipe(switchMap(continueOp => {
    ////  return continueOp ? continuation() : throwError('session expired');
    ////}));
  }
}
