import { Component } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


@Component({
    templateUrl: 'home.component.html',
    styleUrls: [ './home.component.scss' ]
})

export class HomeComponent {
  userlist: any;
  certlist: any;

  events: Array<string> = [];

  constructor() {
    
  }
 
}
