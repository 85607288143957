import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CertifiedExaminerSelectionApplicationDetailViewComponent } from './pages/CertifiedExaminerSelectionApplicationDetailView/CertifiedExaminerSelectionApplicationDetailView.component';
import { CertifiedExaminerSelectionApplicationSubmissionViewComponent } from './pages/CertifiedExaminerSelectionApplicationDetailView/CertifiedExaminerSelectionApplicationSubmissionView.component';
import { CertifiedExaminerPasscodeViewComponent } from './pages/CertifiedExaminerPasscodeView/CertifiedExaminerPasscodeView.component';
import { CertifiedExaminerConfirmationDetailViewComponent } from './pages/CertifiedExaminerConfirmationDetailView/CertifiedExaminerConfirmationDetailView.component';
import { CertifiedExaminerEligibilityViewComponent } from './pages/CertifiedExaminerEligibilityView/CertifiedExaminerEligibilityView.component';
import { CertifiedExaminerEligibilitySubmissionViewComponent } from './pages/CertifiedExaminerEligibilityView/CertifiedExaminerEligibilitySubmissionView.component';
import { DevExtremeModule} from 'devextreme-angular';
import { CommonModule } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';
import { RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

const routes: Routes = [
  {
    path: '',
    component: CertifiedExaminerSelectionApplicationDetailViewComponent,
    data: { showNavbar: true, menuOpened: false}
  },
  {
    path: 'home',
    component: CertifiedExaminerSelectionApplicationDetailViewComponent,
    data: { showNavbar: true, menuOpened: false}
  },
  {
    path: 'CertifiedExaminerSelectionApplicationDetailView',
    component: CertifiedExaminerSelectionApplicationDetailViewComponent,
    data: { showNavbar: true, menuOpened: false}
  },
  {
    path: 'CertifiedExaminerConfirmationDetailView/:id',
    component: CertifiedExaminerConfirmationDetailViewComponent,
    data: { showNavbar: false, menuOpened: false}
  },
  {
    path: 'CertifiedExaminerEligibilityView/:id',
    component: CertifiedExaminerEligibilityViewComponent,
    data: { showNavbar: false, menuOpened: false}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), DevExtremeModule, BrowserModule, CommonModule, RecaptchaModule.forRoot()],
  exports: [RouterModule],
  declarations: [HomeComponent, ProfileComponent, CertifiedExaminerSelectionApplicationDetailViewComponent, CertifiedExaminerPasscodeViewComponent, CertifiedExaminerConfirmationDetailViewComponent, CertifiedExaminerEligibilityViewComponent, CertifiedExaminerEligibilitySubmissionViewComponent, CertifiedExaminerSelectionApplicationSubmissionViewComponent]
})
export class AppRoutingModule { }
