import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from "@angular/common";
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule } from './layouts';
import { FooterModule } from './shared/components/footer/footer.component';
import { CertifiedExaminerSelectionApplicationService } from './core/services/CertifiedExaminerSelectionApplication.service';
import { AntiforgeryService } from './core/services/Antiforgery.Service';
import { CertifiedExaminerSelectionApplicationEndpoint } from './core/services/CertifiedExaminerSelectionApplicationEndpoint.service';
import { ConfigurationService } from './core/services/configuration.service';
import { AlertService } from './core/services/alert.service';
import { LocalStoreManager } from './core/services/local-store-manager.service';
import { EndpointFactory } from './core/services/endpoint-factory.service';
import { CertifiedExaminerSelectionApplicationDetailViewComponent } from './pages/CertifiedExaminerSelectionApplicationDetailView/CertifiedExaminerSelectionApplicationDetailView.component';
import { CertifiedExaminerSelectionApplicationSubmissionViewComponent } from './pages/CertifiedExaminerSelectionApplicationDetailView/CertifiedExaminerSelectionApplicationSubmissionView.component';
import { CertifiedExaminerPasscodeViewComponent } from './pages/CertifiedExaminerPasscodeView/CertifiedExaminerPasscodeView.component';
import { CertifiedExaminerConfirmationDetailViewComponent } from './pages/CertifiedExaminerConfirmationDetailView/CertifiedExaminerConfirmationDetailView.component';
import { CertifiedExaminerEligibilityViewComponent } from './pages/CertifiedExaminerEligibilityView/CertifiedExaminerEligibilityView.component';
import { CertifiedExaminerEligibilitySubmissionViewComponent } from './pages/CertifiedExaminerEligibilityView/CertifiedExaminerEligibilitySubmissionView.component';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS  } from 'ng-recaptcha';

const appInitializerFn = (appConfig: ConfigurationService) => {
  return () => {
    return appConfig.loadConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    FooterModule,
    HttpClientModule,
    RecaptchaModule
  ],
  providers: [
    CertifiedExaminerSelectionApplicationService,
    CertifiedExaminerSelectionApplicationEndpoint,
    ConfigurationService,
    AlertService,
    LocalStoreManager,
    EndpointFactory,
    { provide: HTTP_INTERCEPTORS, useClass: AntiforgeryService, multi: true },
    //{
    //  provide: RECAPTCHA_SETTINGS,
    //  useValue: { siteKey: '6Lc8f5wUAAAAAJxlvqu3Ateu1EpGm6CUVvZ9ejFJ', secretKey: "6Lc8f5wUAAAAAEu3iHFcjr9DMywPmgZuwS-GkwfR" } as RecaptchaSettings,
    //},
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigurationService]
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
