import { Component, OnInit, NgModule, Pipe, PipeTransform, enableProdMode, Input, Output, ViewChild, EventEmitter  } from '@angular/core';
import { BrowserModule, DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { RouterModule, Router, ActivatedRoute, Params } from '@angular/router';

import { CertifiedExaminerSelectionApplicationService } from '../../core/services/CertifiedExaminerSelectionApplication.service';
import notify from 'devextreme/ui/notify';
import { CertifiedExaminerRandomSelectionDto } from '../../core/models/CertifiedExaminerRandomSelectionDto.model';
import { CertifiedExaminerSelectionApplicationDto } from '../../core/models/CertifiedExaminerSelectionApplication.model';


@Component({
  selector: 'CertifiedExaminerPasscodeView',
  templateUrl: './CertifiedExaminerPasscodeView.component.html'
})

export class CertifiedExaminerPasscodeViewComponent { //implements OnInit {
  @Input('certifiedExaminerRandomSelection') certifiedExaminerRandomSelection: CertifiedExaminerRandomSelectionDto;
  @Input('certifiedExaminerSelectionApplication') certifiedExaminerSelectionApplication: CertifiedExaminerSelectionApplicationDto;
  @Output() onCERandomSlectionChange = new EventEmitter<CertifiedExaminerRandomSelectionDto>();
  @Output() onCEApplicationChange = new EventEmitter<CertifiedExaminerSelectionApplicationDto>();
  @Input() viewName: string;
  isHeaderVisible: boolean = true;
  urlCode: string;
  passcodeValue: string;
  isLoadPanelVisible = false;
 
  //certifiedExaminerSelectionApplication: CertifiedExaminerSelectionApplicationDto;
  //certifiedExaminerRandomSelection: CertifiedExaminerRandomSelectionDto;
  
  constructor(private certifiedExaminerSelectionApplicationService: CertifiedExaminerSelectionApplicationService, private router: Router, private sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute) {
    this.passcodeButtonClick = this.passcodeButtonClick.bind(this);
  }

  passcodeButtonOptions: any = {
    text: "Submit Passcode",
    type: "success",
    mode: "contained",
    elementAttr: { "id": "certdownload" },
    onClick: 'passcodeButtonClick'
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.urlCode = params['id'];
    });
  }

 
  passcodeButtonClick(data) {
  
    let combinedCode = this.urlCode + this.passcodeValue;
    this.isLoadPanelVisible = true;
    //console.log(this.viewName);
    //console.log(combinedCode);
       
    // Open Confirmation page when the application is completed
    if (this.viewName == "CertifiedExaminerConfirmationDetailView") {
      this.certifiedExaminerSelectionApplicationService.getRandomSelectionForConfirmation(combinedCode)
      .subscribe(response => this.saveSuccessHelper(response), error => this.saveFailedHelper(error));

    }
    // Open CE Eligibility page when the application is not completed
    else if (this.viewName == "CertifiedExaminerEligibilityView") {
      
    this.certifiedExaminerSelectionApplicationService.getRandomSelectionForEligibility(combinedCode)
      .subscribe(response => this.saveSuccessHelper(response), error => this.saveFailedHelper(error));
    }
  }

  private saveSuccessHelper(certifiedExaminerRandomSelection?: CertifiedExaminerRandomSelectionDto) {
    this.isLoadPanelVisible = false;
    if (certifiedExaminerRandomSelection != null) {
      //notify("Passcode Success!.", "success", 5000);
      this.isHeaderVisible = false;
      this.certifiedExaminerRandomSelection = certifiedExaminerRandomSelection;
      this.certifiedExaminerSelectionApplication = this.certifiedExaminerRandomSelection.certifiedExaminerSelectionApplication;
      this.onCERandomSlectionChange.emit(this.certifiedExaminerRandomSelection);
      this.onCEApplicationChange.emit(this.certifiedExaminerSelectionApplication);
    } else {
      notify("Invalid passcode or page has expired. Please try again.", "error", 5000);
    }
  }

  private saveFailedHelper(error: any) {
    console.log(error.toString());
    notify("Invalid passcode or page has expired. Please try again.", "error", 5000);
  }
}
