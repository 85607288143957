import { Component, OnInit, NgModule, Pipe, PipeTransform, enableProdMode, Input, ViewChild } from '@angular/core';
import { BrowserModule, DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { RouterModule, Router, ActivatedRoute, Params } from '@angular/router';
import { confirm } from 'devextreme/ui/dialog';
import { CertifiedExaminerSelectionApplicationService } from '../../core/services/CertifiedExaminerSelectionApplication.service';
import { AlertService, MessageSeverity } from '../../core/services/alert.service';
import notify from 'devextreme/ui/notify';
import { CertifiedExaminerRandomSelectionDto } from '../../core/models/CertifiedExaminerRandomSelectionDto.model';
import { CertifiedExaminerSelectionApplicationDto } from '../../core/models/CertifiedExaminerSelectionApplication.model';
import { Utilities } from '../../shared/utilities/utilities';
import { BusinessRuleMessage } from '../../core/models/BusinessRuleMessage.model';

@Component({
  selector: 'CertifiedExaminerSelectionApplicationSubmissionView',
  templateUrl: './CertifiedExaminerSelectionApplicationSubmissionView.component.html'
})

export class CertifiedExaminerSelectionApplicationSubmissionViewComponent { //implements OnInit {
  @Input('certifiedExaminerSelectionApplication') certifiedExaminerSelectionApplication: CertifiedExaminerSelectionApplicationDto;
  @Input('isSubmitted') isSubmitted: boolean;
  isPopupVisible = false;
  isLoadPanelVisible = false;
  //collapsed = false;
  pdfSrc: SafeResourceUrl;

  constructor(private alertService: AlertService, private certifiedExaminerSelectionApplicationService: CertifiedExaminerSelectionApplicationService, private router: Router, private sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute) {
    this.previewCEApplication = this.previewCEApplication.bind(this);
    this.print = this.print.bind(this);
    this.download = this.download.bind(this);
  }
  
  previewCEApplicationButtonOption: any = {
    text: "Preview Certified Examiner Selection Application",
    type: "default",
    onClick: 'previewCEApplication',
    width: 400
  }

  ngOnInit() {
   
  }

  previewCEApplication(e) {
    //console.log(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID);
    //console.log(this.urlCode);

    //TODO the print PDF URL
   this.isLoadPanelVisible = true;
    this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerApplication(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID).subscribe(response => {
      this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerApplicationReportURL(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID));
      console.log(this.pdfSrc);
      this.isPopupVisible = true;
      this.isLoadPanelVisible = false;
    });

    //e.preventDefault();
  }

  print() {
    ////window.frames["print_frame"].focus();
    ////window.frames["print_frame"].print();
    console.log(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID);
    this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerApplication(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID).subscribe(response => {
      const objectUrl: string = URL.createObjectURL(response);
      window.open(objectUrl).print();
    });
  }

  download() {
    ////console.log(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID);
    this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerApplication(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID).subscribe(response => {
      var fileName = "CertifiedExaminerSelectionApplication" + ".pdf";
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } else {
        const objectUrl: string = URL.createObjectURL(response);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
      }
    });
  }
}
