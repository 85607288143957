import { Component, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
declare var require: any;

@Component({
    selector: 'app-footer',
    //template: `
    //    <footer><ng-content></ng-content></footer>
    //`,
    templateUrl: 'footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

  logoImage: string;
  footerText: string;

  constructor() {
    this.logoImage = require("src/assets/images/page-footer-logo.png");
    this.footerText = " Copyright © 2018 Government of Alberta. ";
  }

}

@NgModule({
  imports: [
    CommonModule,  
    DxToolbarModule
  ],
    declarations: [ FooterComponent ],
    exports: [ FooterComponent ]
})
export class FooterModule { }
