export const navigation = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
  }
  //,
  // {
  //    text: 'Certified Examiner Application',
  //    path: '/CertifiedExaminerSelectionApplicationDetailView',
  //    icon: 'user'
  //}
];
