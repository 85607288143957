import { Component, OnInit, NgModule, Pipe, PipeTransform, enableProdMode, Input, ViewChild } from '@angular/core';
import { BrowserModule, DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { RouterModule, Router, ActivatedRoute, Params } from '@angular/router';
import { confirm } from 'devextreme/ui/dialog';
import {DxFormComponent} from 'devextreme-angular';
import { CertifiedExaminerSelectionApplicationService } from '../../core/services/CertifiedExaminerSelectionApplication.service';
import { AlertService, MessageSeverity } from '../../core/services/alert.service';
import notify from 'devextreme/ui/notify';
import { CertifiedExaminerRandomSelectionDto } from '../../core/models/CertifiedExaminerRandomSelectionDto.model';
import { CertifiedExaminerSelectionApplicationDto } from '../../core/models/CertifiedExaminerSelectionApplication.model';
import { Utilities } from '../../shared/utilities/utilities';
import { BusinessRuleMessage } from '../../core/models/BusinessRuleMessage.model';

@Component({
  selector: 'CertifiedExaminerEligibilitySubmissionView',
  templateUrl: './CertifiedExaminerEligibilitySubmissionView.component.html'
})

export class CertifiedExaminerEligibilitySubmissionViewComponent { //implements OnInit {
  @Input('certifiedExaminerRandomSelection') certifiedExaminerRandomSelection: CertifiedExaminerRandomSelectionDto;
  @Input('certifiedExaminerSelectionApplication') certifiedExaminerSelectionApplication: CertifiedExaminerSelectionApplicationDto;
  urlCode: string;
  @Input('isSubmitted') isSubmitted: boolean;
  isPopupVisible = false;
  isLoadPanelVisible = false;
  pdfSrc: SafeResourceUrl;

  constructor(private alertService: AlertService, private certifiedExaminerSelectionApplicationService: CertifiedExaminerSelectionApplicationService, private router: Router, private sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute) {
    this.previewEligibilityLetter = this.previewEligibilityLetter.bind(this);
    this.print = this.print.bind(this);
    this.download = this.download.bind(this);
  }

  previewEligibilityLetterButtonOption: any = {
    text: "Preview Certified Examiner Eligibility Letter",
    type: "default",
    onClick: 'previewEligibilityLetter',
    width: 400
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.urlCode = params['id'];
    });
  }


  previewEligibilityLetter(e) {
      this.isLoadPanelVisible = true;
      this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerSelection(this.urlCode).subscribe(response => {
      this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerSelectionReportURL(this.urlCode));
      console.log(this.pdfSrc);
      this.isPopupVisible = true;
      this.isLoadPanelVisible = false;
    });

    //e.preventDefault();
  }

  print() {
    this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerSelection(this.urlCode).subscribe(response => {
      const objectUrl: string = URL.createObjectURL(response);
      window.open(objectUrl).print();
    });
  }

  download() {
    this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerSelection(this.urlCode).subscribe(response => {
      var fileName = "CertifiedExaminerSelection" + ".pdf";
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } else {
        const objectUrl: string = URL.createObjectURL(response);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
      }
    });
  }
}
