import { Component, OnInit, NgModule, Pipe, PipeTransform, enableProdMode, Input, ViewChild } from '@angular/core';
import { BrowserModule, DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { RouterModule, Router } from '@angular/router';
import { CommonModule } from "@angular/common";
import { Observable } from 'rxjs';
import { confirm } from 'devextreme/ui/dialog';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DxFormComponent } from 'devextreme-angular';
import { ConfigurationService } from '../../core/services/configuration.service';
import { CertifiedExaminerSelectionApplicationService } from '../../core/services/CertifiedExaminerSelectionApplication.service';
import { AlertService, MessageSeverity } from '../../core/services/alert.service';
import notify from 'devextreme/ui/notify';
import { CertifiedExaminerSelectionApplicationDto } from '../../core/models/CertifiedExaminerSelectionApplication.model';
import { CertifiedExaminerClaimantGenderVldDto } from '../../core/models/CertifiedExaminerClaimantGenderVld.model';
import { CountryVldDto } from '../../core/models/CountryVld.model';
import { ProvStateVldDto } from '../../core/models/ProvStateVld.model';
import { CityVldDto } from '../../core/models/CityVld.model';
import { Utilities } from '../../shared/utilities/utilities';
import { BusinessRuleMessage } from '../../core/models/BusinessRuleMessage.model';
import { InsuranceCompanyListItem } from '../../core/models/InsuranceCompanyListItem.model';
import { CertifiedExaminerListItem } from '../../core/models/CertifiedExaminerListItem.model';
import { CertifiedExaminerApplicationTypeVldDto } from '../../core/models/CertifiedExaminerApplicationTypeVld.model';


@Component({
  selector: 'CertifiedExaminerSelectionApplicationDetailView',
  templateUrl: './CertifiedExaminerSelectionApplicationDetailView.component.html',
  styleUrls: ['./CertifiedExaminerSelectionApplicationDetailView.component.scss']
})
export class CertifiedExaminerSelectionApplicationDetailViewComponent implements OnInit {
  @ViewChild(DxFormComponent) form: DxFormComponent;
  //private isSaving = false;
  //private showValidationErrors = false;
  //public innerHtml: SafeHtml;
  isPopupVisible = false;
  isLoadPanelVisible = false;
  businessRuleMessages: BusinessRuleMessage[];
  salutations: string[];
  certifiedExaminerSelectionApplication: CertifiedExaminerSelectionApplicationDto;
  genders: CertifiedExaminerClaimantGenderVldDto[];
  countries: CountryVldDto[];
  provStates: ProvStateVldDto[];
  cities: CityVldDto[];
  certifiedExaminerApplicationTypes: CertifiedExaminerApplicationTypeVldDto[];
  certifiedExaminers: CertifiedExaminerListItem[];
  insuranceCompanies: InsuranceCompanyListItem[];
  provStatesForSelectedCountryClaimant: ProvStateVldDto[];
  citiesForSelectedProvStateClaimant: string[];
  provStatesForSelectedCountryClaimantLawyer: ProvStateVldDto[];
  citiesForSelectedProvStateClaimantLawyer: string[];
  provStatesForSelectedCountryInsuranceContact: ProvStateVldDto[];
  citiesForSelectedProvStateInsuranceContact: string[];
  provStatesForSelectedCountryInsuranceLawyer: ProvStateVldDto[];
  citiesForSelectedProvStateInsuranceLawyer: string[];
  //collapsed = false;
  isNotVerified = true;
  //pcodePattern: any = /^[a-zA-Z]\d[a-zA-Z]\d[a-zA-Z]\d$/;
  //pcodePattern: any = /^[A-Z]\d[A-Z]-\d[A-Z]\d$/;
  //phoneRules: any = { X: /[02-9]/ };
  maxDate: Date = new Date();
  pdfSrc: SafeResourceUrl;
  isSubmitted: boolean = false;
  formValidation: any;
  siteKey: string;
  secretKey: string;

  constructor(private alertService: AlertService, private certifiedExaminerSelectionApplicationService: CertifiedExaminerSelectionApplicationService, private router: Router, private sanitizer: DomSanitizer, private configuration: ConfigurationService) {

    this.siteKey = this.configuration.getSiteKey;
    this.secretKey = this.configuration.getSecretKey;

    this.maxDate = new Date();
    this.fieldDataChanged = this.fieldDataChanged.bind(this);
    // bind data
    this.bindData();

    
    //Bind the component to access the component level variables
    this.certifiedExaminerSelectionApplication = new CertifiedExaminerSelectionApplicationDto();

    // set default value to Canada and AB for Country and ProvState
    this.certifiedExaminerSelectionApplication.claimantCountryVldID = 1;
    this.certifiedExaminerSelectionApplication.claimantLawyerCountryVldID = 1;
    this.certifiedExaminerSelectionApplication.insuranceContactCountryVldID = 1;
    this.certifiedExaminerSelectionApplication.insuranceLawyerCountryVldID = 1;

    this.certifiedExaminerSelectionApplication.claimantProvStateVldID = 1;
    this.certifiedExaminerSelectionApplication.claimantLawyerProvStateVldID = 1;
    this.certifiedExaminerSelectionApplication.insuranceContactProvStateVldID = 1;
    this.certifiedExaminerSelectionApplication.insuranceLawyerProvStateVldID = 1;

    this.certifiedExaminerApplicantTypeLookupValueChanged = this.certifiedExaminerApplicantTypeLookupValueChanged.bind(this);
    this.isClaimantRepresentedMustBeChecked = this.isClaimantRepresentedMustBeChecked.bind(this);
    
    this.claimantCountryLookupValueChanged = this.claimantCountryLookupValueChanged.bind(this);
    this.claimantProvinceLookupValueChanged = this.claimantProvinceLookupValueChanged.bind(this);

    this.claimantLawyerCountryLookupValueChanged = this.claimantLawyerCountryLookupValueChanged.bind(this);
    this.claimantLawyerProvinceLookupValueChanged = this.claimantLawyerProvinceLookupValueChanged.bind(this);

    this.insuranceContactCountryLookupValueChanged = this.insuranceContactCountryLookupValueChanged.bind(this);
    this.insuranceContactProvinceLookupValueChanged = this.insuranceContactProvinceLookupValueChanged.bind(this);

    this.insuranceLawyerCountryLookupValueChanged = this.insuranceLawyerCountryLookupValueChanged.bind(this);
    this.insuranceLawyerProvinceLookupValueChanged = this.insuranceLawyerProvinceLookupValueChanged.bind(this);

    this.addCustomCityClaimant = this.addCustomCityClaimant.bind(this);
    this.addCustomCityClaimantLawyer = this.addCustomCityClaimantLawyer.bind(this);
    this.addCustomCityInsuranceContact = this.addCustomCityInsuranceContact.bind(this);
    this.addCustomCityInsuranceLawyer = this.addCustomCityInsuranceLawyer.bind(this);
    this.claimantPhoneRequired = this.claimantPhoneRequired.bind(this);
    this.claimantLawyerRequired = this.claimantLawyerRequired.bind(this);
    this.insuranceRequired = this.insuranceRequired.bind(this);
    this.insuranceLawyerRequired = this.insuranceLawyerRequired.bind(this);
    this.dateCheck = this.dateCheck.bind(this);
    this.provinceListSetDefault = this.provinceListSetDefault.bind(this);
    this.cityListSetDefault = this.cityListSetDefault.bind(this);
  
    //this.claimantPostalCodeMask = this.claimantPostalCodeMask.bind(this);
    ////this.print = this.print.bind(this);
    ////this.download = this.download.bind(this);
    this.claimantPostalCodeChanged = this.claimantPostalCodeChanged.bind(this);
    this.declinedCeCannotBeSame = this.declinedCeCannotBeSame.bind(this);
    this.resolvedCaptcha = this.resolvedCaptcha.bind(this);
    this.upperCase = this.upperCase.bind(this);
}

  submitButtonOptions: any = {
    text: "Submit Form",
    type: "success",
    disabled: true,
    useSubmitBehavior: true,
    onClick: 'showConfirm'
  }

  printButtonOptions: any = {
    text: "Print Form",
    type: "success",
    useSubmitBehavior: true
  }

  postalcodeOptions: any =  {
    mask: "L0L 0L0",
    useMaskedValue: true,
    inputAttr: { 'style': 'text-transform: uppercase', 'autocomplete': 'new' }
  }

  setAutocompleteOff: any = {
    inputAttr: { 'autocomplete': Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}
  }

  phoneOptions: any = {
    mask: "(000) 000-0000",
    useMaskedValue: true,
    inputAttr: { 'autocomplete': Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5) }
  }
  claimantPostalcodeOptions: any = {
    mask: "L0L 0L0",
    useMaskedValue: true,
    inputAttr: { 'style': 'text-transform: uppercase', 'autocomplete': 'new' }
  }

  claimantLawyerPostalcodeOptions: any = {
    mask: "L0L 0L0",
    useMaskedValue: true,
    inputAttr: { 'style': 'text-transform: uppercase', 'autocomplete': 'new'  }
  }

  insurancePostalcodeOptions: any = {
    mask: "L0L 0L0",
    useMaskedValue: true,
    inputAttr: { 'style': 'text-transform: uppercase', 'autocomplete': 'new' }
  }

  insuranceLawyerPostalcodeOptions: any = {
    mask: "L0L 0L0",
    useMaskedValue: true,
    inputAttr: { 'style': 'text-transform: uppercase', 'autocomplete': 'new' }
  }

  downloadbuttonOptions: any = {
    elementAttr: { "id": "certdownload" },
    icon: 'download',
    onClick: 'download'
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    this.fieldDataChanged(this.formValidation);    
  }

  bindData() {
    this.salutations = this.certifiedExaminerSelectionApplicationService.getSalutations();
    this.certifiedExaminerSelectionApplicationService.getCertifiedExaminerApplicationTypes().subscribe(response => { this.certifiedExaminerApplicationTypes = response; });
    this.certifiedExaminerSelectionApplicationService.getGenders().subscribe(response => { this.genders = response; });
    this.certifiedExaminerSelectionApplicationService.getInsuranceCompanies().subscribe(response => { this.insuranceCompanies = response; });
    this.certifiedExaminerSelectionApplicationService.getCertifiedExaminers().subscribe(response => { this.certifiedExaminers = response; });
    this.certifiedExaminerSelectionApplicationService.getCountries().subscribe(response => { this.countries = response; });
    this.certifiedExaminerSelectionApplicationService.getProvinceStates().subscribe(response => { this.provStates = response; this.provinceListSetDefault(); });
    this.certifiedExaminerSelectionApplicationService.getCities().subscribe(response => { this.cities = response; this.cityListSetDefault();  });
    //this.citiesForSelectedProvStateClaimant = [];
    //this.citiesForSelectedProvStateClaimantLawyer = [];
    //this.citiesForSelectedProvStateInsuranceContact = [];
    //this.citiesForSelectedProvStateInsuranceLawyer = [];
  }

  provinceListSetDefault() {
    this.provStatesForSelectedCountryClaimant = this.provStates.filter(x => x.countryVldID == 1);
    this.provStatesForSelectedCountryClaimantLawyer = this.provStates.filter(x => x.countryVldID == 1);
    this.provStatesForSelectedCountryInsuranceContact = this.provStates.filter(x => x.countryVldID == 1);
    this.provStatesForSelectedCountryInsuranceLawyer = this.provStates.filter(x => x.countryVldID == 1);

    
  }

  cityListSetDefault() {
    this.citiesForSelectedProvStateClaimant = this.cities.filter(x => x.provStateVldID == 1).map(a => a.cityName);
    this.citiesForSelectedProvStateClaimantLawyer = this.cities.filter(x => x.provStateVldID == 1).map(a => a.cityName);
    this.citiesForSelectedProvStateInsuranceContact = this.cities.filter(x => x.provStateVldID == 1).map(a => a.cityName);
    this.citiesForSelectedProvStateInsuranceLawyer = this.cities.filter(x => x.provStateVldID == 1).map(a => a.cityName);

    this.form.instance.repaint();
    this.form.instance.validate();
    ////this.form.instance.option("formData").claimantCountryVldID = 1;
  }

  claimantPostalCodeChanged(data) {
    let str = data.text;
    data.text = str.toLocaleUpperCase();
    console.log(str.toLocaleUpperCase());
  }

  // When the application type drop down is Claimant's Legal Representative, update the isClaimantRepresented value to true 
  certifiedExaminerApplicantTypeLookupValueChanged(data) {
    let selectedcertifiedExaminerApplicantTypeId = data.value;

    if (selectedcertifiedExaminerApplicantTypeId == 4) {
      this.certifiedExaminerSelectionApplication.isClaimantRepresented = true;
    } else {
      this.certifiedExaminerSelectionApplication.isClaimantRepresented = false;
    }
  }

  // if application type is Claimant's Lawyer, the checkbox isClaimantRepresented must be checked
  isClaimantRepresentedMustBeChecked(data) {
    if (data.value == true) {
      return true;
    }
    if (this.certifiedExaminerSelectionApplication.certifiedExaminerApplicantTypeVldID == 4 && data.value == false) {
      return false;
    }
    if ((this.certifiedExaminerSelectionApplication.claimantLawyerFirstName ||
      this.certifiedExaminerSelectionApplication.claimantLawyerLastName) && data.value == false) {
      return false;
    }
    else {
      return true;
    }
  }

  // Filter Claimant Province List based on Country selected
  claimantCountryLookupValueChanged(data) {
    ////console.log(this.postalcodeOptions.mask);
    let selectedCountryId = data.value;
    this.provStatesForSelectedCountryClaimant = this.provStates.filter(x => x.countryVldID == selectedCountryId);

    // Remove postal code mask when country is not canada
    if (selectedCountryId != 1) {
      this.claimantPostalcodeOptions = {
        mask: "",
        useMaskedValue: true,
        inputAttr: { 'style': 'text-transform: uppercase' }
      }
    } else {
      this.claimantPostalcodeOptions = {
        mask: "L0L 0L0",
        useMaskedValue: true,
        inputAttr: { 'style': 'text-transform: uppercase' }
      }
    }

    ////// For Canada, show the postal code field with mask
    ////if (selectedCountryId === 1) {
    ////  ////this.postalcodeOptions.mask = "000-000-0000";
    ////  this.showClaimantPostalCodeMask = true;
    ////  this.hideClaimantPostalCodeMask = false;
    ////  //console.log(this.showClaimantPostalCodeMask);
    ////  //console.log(this.hideClaimantPostalCodeMask);
    ////}

    ////// For Other country, show the postal code field without mask
    ////if (selectedCountryId != 1) {
    ////  ////this.postalcodeOptions.mask = "000-000-0000";
    ////  this.showClaimantPostalCodeMask = false;
    ////  this.hideClaimantPostalCodeMask = true;
    ////  //console.log(this.showClaimantPostalCodeMask);
    ////  //console.log(this.hideClaimantPostalCodeMask);
    ////}
  }

  // Filter Claimant Lawyer Province List based on Country selected
  claimantLawyerCountryLookupValueChanged(data) {
    let selectedCountryId = data.value;
    this.provStatesForSelectedCountryClaimantLawyer = this.provStates.filter(x => x.countryVldID == selectedCountryId);

    // Remove postal code mask when country is not canada
    if (selectedCountryId != 1) {
        this.claimantLawyerPostalcodeOptions = {
        mask: "",
        useMaskedValue: true,
          inputAttr: { 'style': 'text-transform: uppercase' }
      }
    } else {
        this.claimantLawyerPostalcodeOptions = {
        mask: "L0L 0L0",
        useMaskedValue: true,
        inputAttr: { 'style': 'text-transform: uppercase' }
      }
    }
  }

  // Filter Insurance contact Province List based on Country selected
  insuranceContactCountryLookupValueChanged(data) {
    let selectedCountryId = data.value;
    this.provStatesForSelectedCountryInsuranceContact = this.provStates.filter(x => x.countryVldID == selectedCountryId);

    // Remove postal code mask when country is not canada
    if (selectedCountryId != 1) {
      this.insurancePostalcodeOptions = {
        mask: "",
        useMaskedValue: true,
        inputAttr: { 'style': 'text-transform: uppercase' }
      }
    } else {
      this.insurancePostalcodeOptions = {
        mask: "L0L 0L0",
        useMaskedValue: true,
        inputAttr: { 'style': 'text-transform: uppercase' }
      }
    }
  }

  // Filter Insurance Lawyer Province List based on Country selected
  insuranceLawyerCountryLookupValueChanged(data) {
    let selectedCountryId = data.value;
    this.provStatesForSelectedCountryInsuranceLawyer = this.provStates.filter(x => x.countryVldID == selectedCountryId);
    // Remove postal code mask when country is not canada
    if (selectedCountryId != 1) {
      this.insuranceLawyerPostalcodeOptions = {
        mask: "",
        useMaskedValue: true,
        inputAttr: { 'style': 'text-transform: uppercase' }
      }
    } else {
      this.insuranceLawyerPostalcodeOptions = {
        mask: "L0L 0L0",
        useMaskedValue: true,
        inputAttr: { 'style': 'text-transform: uppercase' }
      }
    }
  }

  upperCase(a) {
    setTimeout(function () {
      a.value = a.value.toUpperCase();
    }, 1);
    console.log(a.value);
  }

  // Filter Claimant City List based on Province selected
  claimantProvinceLookupValueChanged(data) {
    let selectedprovStateId = data.value;
    this.citiesForSelectedProvStateClaimant = this.cities.filter(x => x.provStateVldID == selectedprovStateId).map(a => a.cityName);
    //console.log(this.citiesForSelectedProvStateClaimant);
  }
  
  // Filter Claimant Lawyer City List based on Province selected
  claimantLawyerProvinceLookupValueChanged(data) {
    let selectedprovStateId = data.value;
    this.citiesForSelectedProvStateClaimantLawyer = this.cities.filter(x => x.provStateVldID == selectedprovStateId).map(a => a.cityName);
    //console.log(this.citiesForSelectedProvStateClaimantLawyer);
  }

  // Filter Insurance contact City List based on Province selected
  insuranceContactProvinceLookupValueChanged(data) {
    let selectedprovStateId = data.value;
    this.citiesForSelectedProvStateInsuranceContact = this.cities.filter(x => x.provStateVldID == selectedprovStateId).map(a => a.cityName);
    //console.log(this.citiesForSelectedProvStateInsuranceContact);
  }

  // Filter Insurance Lawyer City List based on Province selected
  insuranceLawyerProvinceLookupValueChanged(data) {
    let selectedprovStateId = data.value;
    this.citiesForSelectedProvStateInsuranceLawyer = this.cities.filter(x => x.provStateVldID == selectedprovStateId).map(a => a.cityName);
    //console.log(this.citiesForSelectedProvStateInsuranceLawyer);
  }

  // Add new Claimant City entered from text box to data binding
  addCustomCityClaimant(data) {
    //if (!data.customItem) {
    //   data.customItem = data.text;
    //}

    //console.log("claimant");
    var newItem = data.text;
    this.citiesForSelectedProvStateClaimant.push(newItem);
    data.customItem = newItem;
    //console.log(data.customItem);
  }

  // Add new Claimant Lawyer City entered from text box to data binding
  addCustomCityClaimantLawyer(data) {
    //console.log(data.text);
    //console.log("claimant lawyer");
    var newItem = data.text;
    this.citiesForSelectedProvStateClaimantLawyer.push(newItem);
    data.customItem = newItem;
    //console.log(data.customItem);
  }

  // Add new Insurance Lawyer City entered from text box to data binding
  addCustomCityInsuranceContact(data) {
    ////console.log(data.text);
    ////console.log("Insurance");
    var newItem = data.text;
    this.citiesForSelectedProvStateInsuranceContact.push(newItem);
    data.customItem = newItem;
    //console.log(data.customItem);
  }

  // Add new Insurance Lawyer City entered from text box to data binding
  addCustomCityInsuranceLawyer(data) {
    ////console.log(data.text);
    ////console.log("Insurance lawyer");
    var newItem = data.text;
    this.citiesForSelectedProvStateInsuranceLawyer.push(newItem);
    data.customItem = newItem;
    //console.log(data.customItem);
  }

  ////claimantPostalCodeMask() {
  ////  if (this.certifiedExaminerSelectionApplication.claimantCountryVldID == 1) {
  ////    console.log(this.pcodePattern);
  ////    return this.pcodePattern;
  ////  }
  ////  else {
  ////    return null;
  ////  }
  ////}

  // Validation rule for Claimant Lawyer: if first name or last name is entered or the isClaimantRepresented checkbox checked, all other info is required
  claimantLawyerRequired(e) {
    if (e.value) {
      return true;
    }
    if (this.certifiedExaminerSelectionApplication.certifiedExaminerApplicantTypeVldID == 4) {
      return false;
    }
    else {
      if (this.certifiedExaminerSelectionApplication.claimantLawyerFirstName ||
        this.certifiedExaminerSelectionApplication.claimantLawyerLastName ||
        this.certifiedExaminerSelectionApplication.isClaimantRepresented) {
        return false;
      }
      return true;
    };
  }

  //Validate Insurance contact info must be entered (invalid data if return false)
  insuranceRequired(e) {
    //console.log(e);
    if (e.value) {
      return true;
    }

    if (this.certifiedExaminerSelectionApplication.certifiedExaminerApplicantTypeVldID == 1 ) {
      return false;
    }
    else {
      if (this.certifiedExaminerSelectionApplication.insuranceContactFirstName ||
        this.certifiedExaminerSelectionApplication.insuranceContactLastName) {
        return false;
      }

      if (this.certifiedExaminerSelectionApplication.insuranceLawyerFirstName ||
        this.certifiedExaminerSelectionApplication.insuranceLawyerLastName) {
        return true;
      }

      if (this.certifiedExaminerSelectionApplication.certifiedExaminerApplicantTypeVldID == 2) {
        return true;
      } 
      return false;
    };
    //return true;
  }

  //Validate Insurance Lawyer info must be entered (invalid data if return false)
  insuranceLawyerRequired(e) {
    if (e.value) {
      return true;
    }

    if (this.certifiedExaminerSelectionApplication.certifiedExaminerApplicantTypeVldID == 2) {
      return false;
    }
    else {
     
      if (this.certifiedExaminerSelectionApplication.insuranceLawyerFirstName ||
        this.certifiedExaminerSelectionApplication.insuranceLawyerLastName) {
        return false;
      }

      if (this.certifiedExaminerSelectionApplication.insuranceContactFirstName ||
        this.certifiedExaminerSelectionApplication.insuranceContactLastName) {
        return true;
      }

      if (this.certifiedExaminerSelectionApplication.certifiedExaminerApplicantTypeVldID == 1) {
        return true;
      }

      return false;
    };
  }

  //Validate one of the Claimant phone number must be entered 
  claimantPhoneRequired() {
    return this.certifiedExaminerSelectionApplication.claimantHomePhone || this.certifiedExaminerSelectionApplication.claimantWorkPhone || this.certifiedExaminerSelectionApplication.claimantCellPhone;
  }

  declinedCeCannotBeSame() {
    return this.certifiedExaminerSelectionApplication.insuranceDeclinedCertifiedExaminerContactID != this.certifiedExaminerSelectionApplication.claimantDeclinedCertifiedExaminerContactID;
  }

  //Validate Claimant DOB/Accident Date cannot be a future date
  dateCheck(e) {
    var today = new Date();
    today = new Date(today.setDate(today.getDate() - 1));
    
    var isGreater = Utilities.compareDate(today, e.value);
    if (isGreater == -1) {
      return false;
    }
    else {
      return true;
    }
  }

  fieldDataChanged(e: any) {
    if (this.form != undefined) {
      this.form.instance.validate();
    }
  }

  //print() {
  //  ////window.frames["print_frame"].focus();
  //  ////window.frames["print_frame"].print();
  //  this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerApplication(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID).subscribe(response => {
  //    const objectUrl: string = URL.createObjectURL(response);
  //    window.open(objectUrl).print();
  //  });
  //}

  //download() {
  //  ////console.log(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID);
  //  this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerApplication(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID).subscribe(response => {
  //    var fileName = "CertifiedExaminerSelectionApplication" + ".pdf";
  //    if (window.navigator.msSaveOrOpenBlob) {
  //      window.navigator.msSaveOrOpenBlob(response, fileName);
  //    } else {
  //      const objectUrl: string = URL.createObjectURL(response);
  //      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

  //      a.href = objectUrl;
  //      a.download = fileName;
  //      document.body.appendChild(a);
  //      a.click();

  //      document.body.removeChild(a);
  //      URL.revokeObjectURL(objectUrl);
  //    }
  //  });
  //}

  resolvedCaptcha(captchaResponse: string) {
   if (captchaResponse != null) {
     //// console.log(`Resolved captcha with response ${captchaResponse}:`);
     //Enable the submit button if the captch is verifed.
     this.submitButtonOptions = {
       text: "Submit Form",
       type: "success",
       disabled: false,
       useSubmitBehavior: true,
       onClick: 'showConfirm'
     }
      this.isNotVerified = false;
    }
  }

  submit(e) {
    //this.isSaving = true;
    
    var result = confirm("Are you sure you want to submit the application? You will not be able to make any changes after submission. ", "Confirm Submission");
    result.then((dialogResult: any) => {
      if (dialogResult) {
        
        this.isLoadPanelVisible = true;
        this.alertService.startLoadingMessage("Submitting application...");

        // Update all postalcode to uppercase
        this.certifiedExaminerSelectionApplication.claimantPostalCode = this.certifiedExaminerSelectionApplication.claimantPostalCode.toUpperCase();

        if (this.certifiedExaminerSelectionApplication.claimantLawyerPostalCode != null) {
          this.certifiedExaminerSelectionApplication.claimantLawyerPostalCode = this.certifiedExaminerSelectionApplication.claimantLawyerPostalCode.toUpperCase();
        }

        if (this.certifiedExaminerSelectionApplication.insuranceContactPostalCode != null) {
          this.certifiedExaminerSelectionApplication.insuranceContactPostalCode = this.certifiedExaminerSelectionApplication.insuranceContactPostalCode.toUpperCase();
        }

        if (this.certifiedExaminerSelectionApplication.insuranceLawyerPostalCode != null) {
          this.certifiedExaminerSelectionApplication.insuranceLawyerPostalCode = this.certifiedExaminerSelectionApplication.insuranceLawyerPostalCode.toUpperCase();
        }

        // Submit Application
        this.certifiedExaminerSelectionApplicationService.submitCEApplication(this.certifiedExaminerSelectionApplication)
          .subscribe(certificate => this.saveSuccessHelper(certificate), error => this.saveFailedHelper(error));
      }
    });
   
    e.preventDefault();
  }

  private saveSuccessHelper(businessRuleMessages?: BusinessRuleMessage[]) {
    this.businessRuleMessages = businessRuleMessages;
    //this.isSaving = false;
    this.alertService.stopLoadingMessage();
    //this.showValidationErrors = false;
    this.isLoadPanelVisible = false;
   
    
    // TODO: Get the Application ID from the business Rule Message
    //console.log(businessRuleMessages[1]);
    this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID = businessRuleMessages[1].message;
    //console.log(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID );
  
    // TODO: go to 
    ////this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerApplication(
    ////  this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID).subscribe(response => {
    ////    this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.certifiedExaminerSelectionApplicationService.getPdfCertifiedExaminerApplicationReportURL(this.certifiedExaminerSelectionApplication.certifiedExaminerSelectionApplicationID));
    ////    this.isPopupVisible = true;
    ////  notify("Certified Examiner Selection Application has been submitted successfully.", "success", 3000);
    ////    this.isLoadPanelVisible = false;
    ////  });
    setTimeout(() => this.isSubmitted = true, 100);
    
  }

  private saveFailedHelper(error: any) {
    console.log(error.toString());
    //this.isSaving = false;
    this.alertService.stopLoadingMessage();
    notify("Errors occured while submitting your application. Please try submit the application again.", "error", 6000);
    this.isLoadPanelVisible = false;
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }
}
