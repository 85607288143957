
export class CertifiedExaminerSelectionApplicationDto {

  public certifiedExaminerSelectionApplicationID: string;
  public certifiedExaminerSelectionApplicationNumber: string;
  public certifiedExaminerApplicantTypeVldID: number;
  public certifiedExaminerSelectionApplicationStatusVldID: number;
  public insuranceCompanyID: string;
  public claimantDeclinedCertifiedExaminerContactID: string;
  public claimantDeclinedCertifiedExaminerName: string;
  public insuranceDeclinedCertifiedExaminerContactID: string;
  public insuranceDeclinedCertifiedExaminerName: string;
  public submittedDate: Date;
  public expiredDate: Date;
  public accidentDate: Date;
  public claimNumber: string;
  public claimantDateofBirth: Date;
  public claimantGenderVldID: number;
  public claimantSalutation: string;
  public claimantFirstName: string;
  public claimantMiddleName: string;
  public claimantLastName: string;
  public claimantAddressLine1: string;
  public claimantAddressLine2: string;
  public claimantCountryVldID: number;
  public claimantProvStateVldID: number;
  public claimantCityName: string;
  public claimantPostalCode: string;
  public claimantEmailAddress: string;
  public claimantHomePhone: string;
  public claimantWorkPhone: string;
  public claimantWorkPhoneExtension: string;
  public claimantCellPhone: string;
  public isClaimantRepresented: boolean; 
  public claimantLawyerLawFirmName: string;
  public claimantLawyerSalutation: string;
  public claimantLawyerFirstName: string;
  public claimantLawyerLastName: string;
  public claimantLawyerAddressLine1: string;
  public claimantLawyerAddressLine2: string;
  public claimantLawyerCountryVldID: number;
  public claimantLawyerProvStateVldID: number;
  public claimantLawyerCityName: string;
  public claimantLawyerPostalCode: string;
  public claimantLawyerEmailAddress: string;
  public claimantLawyerWorkPhone: string;
  public claimantLawyerWorkPhoneExtension: string;
  public claimantLawyerFax: string;
  public insuranceContactSalutation: string;
  public insuranceContactFirstName: string;
  public insuranceContactLastName: string;
  public insuranceContactAddressLine1: string;
  public insuranceContactAddressLine2: string;
  public insuranceContactCountryVldID: number;
  public insuranceContactProvStateVldID: number;
  public insuranceContactCityName: string;
  public insuranceContactPostalCode: string;
  public insuranceContactEmailAddress: string;
  public insuranceContactWorkPhone: string;
  public insuranceContactWorkPhoneExtension: string;
  public insuranceContactFax: string;
  public insuranceLawyerLawFirmName: string;
  public insuranceLawyerSalutation: string;
  public insuranceLawyerFirstName: string;
  public insuranceLawyerLastName: string;
  public insuranceLawyerAddressLine1: string;
  public insuranceLawyerAddressLine2: string;
  public insuranceLawyerCountryVldID: number;
  public insuranceLawyerProvStateVldID: number;
  public insuranceLawyerCityName: string;
  public insuranceLawyerPostalCode: string;
  public insuranceLawyerEmailAddress: string;
  public insuranceLawyerWorkPhone: string;
  public insuranceLawyerWorkPhoneExtension: string;
  public insuranceLawyerFax: string;
  public nameofRequestingParty: string;
  public isSignatureSigned: boolean;
  public webNotes: string;
  public submitterEmailAddress: string;
}
