import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

//import Dto = Insurance.WebExternalUI.DTO;
import { CertifiedExaminerSelectionApplicationEndpoint } from './CertifiedExaminerSelectionApplicationEndpoint.service';
import { CertifiedExaminerSelectionApplicationDto } from '../models/CertifiedExaminerSelectionApplication.model';
import { CertifiedExaminerRandomSelectionDto } from '../models/CertifiedExaminerRandomSelectionDto.model';
import { CertifiedExaminerClaimantGenderVldDto } from '../models/CertifiedExaminerClaimantGenderVld.model';
import { CountryVldDto } from '../models/CountryVld.model';
import { ProvStateVldDto } from '../models/ProvStateVld.model';
import { CityVldDto } from '../models/CityVld.model';
import { CertifiedExaminerApplicationTypeVldDto } from '../models/CertifiedExaminerApplicationTypeVld.model';
import { BusinessRuleMessage } from '../../core/models/BusinessRuleMessage.model';
import { CertifiedExaminerListItem } from '../models/CertifiedExaminerListItem.model';
import { InsuranceCompanyListItem } from '../models/InsuranceCompanyListItem.model';


@Injectable()
export class CertifiedExaminerSelectionApplicationService {

  constructor(private router: Router, private http: HttpClient, private certifiedExaminerSelectionApplicationEndpoint: CertifiedExaminerSelectionApplicationEndpoint) {

  }

  getSalutations() {
    let salutations: string[] = ["Mr.", "Ms.", "Mrs.", "Miss", "None"];
    return salutations;
  }

  getGenders() {

    ////let genderMale = new CertifiedExaminerClaimantGenderVld();
    ////genderMale.certifiedExaminerClaimantGenderVldID = 1;
    ////genderMale.certifiedExaminerClaimantGenderDescription = "Male";

    ////let genderFemale = new CertifiedExaminerClaimantGenderVld();
    ////genderFemale.certifiedExaminerClaimantGenderVldID = 2;
    ////genderFemale.certifiedExaminerClaimantGenderDescription = "Female";

    ////let genders: CertifiedExaminerClaimantGenderVld[] = [genderMale, genderFemale];
    ////return genders;
    return this.certifiedExaminerSelectionApplicationEndpoint.getGenders<CertifiedExaminerClaimantGenderVldDto[]>();
  }

  getCountries() {
    /*
    let can = new CountryVld();
    can.countryVldID = 1;
    can.countryName = "CAN";

    let usa = new CountryVld();
    usa.countryVldID = 2;
    usa.countryName = "USA";

    let countries: CountryVld[] = [can, usa];
    return countries;
    */
    return this.certifiedExaminerSelectionApplicationEndpoint.getCountryVldEndpoint<CountryVldDto[]>();
  }

  getProvinceStates() {
    /*
    let ab = new ProvStateVld();
    ab.provStateVldID = 11;
    ab.provStateName = "Alberta";
    ab.countryVldID = 1;

    let ny = new ProvStateVld();
    ny.provStateVldID = 22;
    ny.provStateName = "New York";
    ny.countryVldID = 2;

    let p: ProvStateVld[] = [ab, ny];
    return p;
    */

    return this.certifiedExaminerSelectionApplicationEndpoint.getProvinceStatesEndpoint<ProvStateVldDto[]>();
  }

  getCities() {
    /*
    let ab = new ProvStateVld();
    ab.provStateVldID = 11;
    ab.provStateName = "Alberta";
    ab.countryVldID = 1;

    let ny = new ProvStateVld();
    ny.provStateVldID = 22;
    ny.provStateName = "New York";
    ny.countryVldID = 2;

    let p: ProvStateVld[] = [ab, ny];
    return p;
    */

    return this.certifiedExaminerSelectionApplicationEndpoint.getCitiesEndpoint<CityVldDto[]>();
  }

  getCertifiedExaminerApplicationTypes() {
    return this.certifiedExaminerSelectionApplicationEndpoint.getCertifiedExaminerApplicationTypesEndpoint<CertifiedExaminerApplicationTypeVldDto[]>();
  }

  getCertifiedExaminers() {
    return this.certifiedExaminerSelectionApplicationEndpoint.getCertifiedExaminersEndpoint<CertifiedExaminerListItem[]>();
  }

  getInsuranceCompanies() {
    return this.certifiedExaminerSelectionApplicationEndpoint.getInsuranceCompaniesEndpoint<InsuranceCompanyListItem[]>();
  }

  submitCEApplication(certifiedExaminerSelectionApplication: CertifiedExaminerSelectionApplicationDto) {
    return this.certifiedExaminerSelectionApplicationEndpoint.submitCEApplicationEndpoint<BusinessRuleMessage[]>(certifiedExaminerSelectionApplication);
  }

  getPdfCertifiedExaminerApplication(certifiedExaminerSelectionApplicationId?: string) {
    return this.certifiedExaminerSelectionApplicationEndpoint.getPdfCertifiedExaminerApplicationEndpoint<Blob>(certifiedExaminerSelectionApplicationId);
  }
  
  getPdfCertifiedExaminerApplicationReportURL(certifiedExaminerSelectionApplicationId?: string): string {
    //console.log(this.certifiedExaminerSelectionApplicationEndpoint.getPdfCertifiedExaminerApplicationReportURLEndpoint(certifiedExaminerSelectionApplicationId));
    return this.certifiedExaminerSelectionApplicationEndpoint.getPdfCertifiedExaminerApplicationReportURLEndpoint(certifiedExaminerSelectionApplicationId);
  }

  getPdfCertifiedExaminerConfirmationLetter(certifiedExaminerRandomSelectionId?: string) {
    return this.certifiedExaminerSelectionApplicationEndpoint.getPdfCertifiedExaminerConfirmationLetterEndpoint<Blob>(certifiedExaminerRandomSelectionId);
  }

  getPdfCertifiedExaminerConfirmationLetterReportURL(certifiedExaminerRandomSelectionId?: string): string {
    //console.log(this.certifiedExaminerSelectionApplicationEndpoint.getPdfCertifiedExaminerConfirmationLetterReportURLEndpoint(certifiedExaminerRandomSelectionId));
    return this.certifiedExaminerSelectionApplicationEndpoint.getPdfCertifiedExaminerConfirmationLetterReportURLEndpoint(certifiedExaminerRandomSelectionId);
  }

  getPdfClaimantConfirmationLetter(certifiedExaminerRandomSelectionId?: string) {
    //console.log(certifiedExaminerRandomSelectionId);
    //console.log(this.certifiedExaminerSelectionApplicationEndpoint.getPdfClaimantConfirmationLetterEndpoint(certifiedExaminerRandomSelectionId));
    return this.certifiedExaminerSelectionApplicationEndpoint.getPdfClaimantConfirmationLetterEndpoint<Blob>(certifiedExaminerRandomSelectionId);
  }

  getPdfClaimantConfirmationLetterReportURL(certifiedExaminerRandomSelectionId?: string): string {
    //console.log(certifiedExaminerRandomSelectionId);
    //console.log(this.certifiedExaminerSelectionApplicationEndpoint.getPdfClaimantConfirmationLetterReportURLEndpoint(certifiedExaminerRandomSelectionId));
    return this.certifiedExaminerSelectionApplicationEndpoint.getPdfClaimantConfirmationLetterReportURLEndpoint(certifiedExaminerRandomSelectionId);
  }

  getRandomSelectionForConfirmation(urlCode: string) {
    return this.certifiedExaminerSelectionApplicationEndpoint.getRandomSelectionForConfirmationEndpoint<CertifiedExaminerRandomSelectionDto>(urlCode);
  }

  getRandomSelectionForEligibility(urlCode: string) {
    return this.certifiedExaminerSelectionApplicationEndpoint.getRandomSelectionForEligibilityEndpoint<CertifiedExaminerRandomSelectionDto>(urlCode);
  }

  acceptSelection(urlCode: string) {
    //return this.certifiedExaminerSelectionApplicationEndpoint.acceptSelectionEndpoint<any>(urlCode);
    return this.certifiedExaminerSelectionApplicationEndpoint.acceptSelectionEndpoint<any>(urlCode);
  }

  declineSelection(urlCode: string) {
    return this.certifiedExaminerSelectionApplicationEndpoint.declineSelectionEndpoint<any>(urlCode);
  }

  getPdfCertifiedExaminerSelection(certifiedExaminerSelectionId?: string) {
    return this.certifiedExaminerSelectionApplicationEndpoint.getPdfCertifiedExaminerSelectionEndpoint<Blob>(certifiedExaminerSelectionId);
  }
  
  getPdfCertifiedExaminerSelectionReportURL(certifiedExaminerSelectionId?: string): string {
    return this.certifiedExaminerSelectionApplicationEndpoint.getPdfCertifiedExaminerSelectionReportURLEndpoint(certifiedExaminerSelectionId);
  }
}
