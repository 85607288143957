import { Injectable } from '@angular/core';
import { DBkeys } from './db-Keys';
import { Utilities } from '../../shared/utilities/utilities';
import { environment } from '../../../environments/environment';
import { LocalStoreManager } from './local-store-manager.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConfigurationService {

    // public baseUrl = environment.WebApiUrl || Utilities.baseUrl();
    public loginUrl = environment.loginUrl;
    public static readonly defaultHomeUrl: string = "/";

    private _homeUrl: string = null;
    private _language: string = null;
    private configuration: IServerConfiguration;

  constructor(private localStorage: LocalStoreManager, private http: HttpClient) {
    this.loadLocalChanges();
  }

  private loadLocalChanges() {
    
    if (this.localStorage.exists(DBkeys.HOME_URL))
      this._homeUrl = this.localStorage.getDataObject<string>(DBkeys.HOME_URL);

   
  }

  set homeUrl(value: string) {
    this._homeUrl = value;
    this.saveToLocalStore(value, DBkeys.HOME_URL);
  }
  get homeUrl() {
    if (this._homeUrl != null)
      return this._homeUrl;

    return ConfigurationService.defaultHomeUrl;
  }

  private saveToLocalStore(data: any, key: string) {
    setTimeout(() => this.localStorage.savePermanentData(data, key));
  }

  loadConfig() {
    return this.http.get<IServerConfiguration>('/api/Configuration/ConfigurationData')
      .toPromise()
      .then(result => {
        this.configuration = <IServerConfiguration>(result);
      }, error => console.error(error));
  }

  get getSiteKey() {
    return this.configuration.SiteKey;
  }

  get getSecretKey() {
    return this.configuration.SecretKey;
  }

  get baseUrl() {
    return this.configuration.WebApiUrl;
  }
}

export interface IServerConfiguration {
  WebApiUrl: string;
  SiteKey: string;
  SecretKey: string;
}
